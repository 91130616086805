<template>
  <div class="headerFlex">
    <div class="container">
      <div class="logo" v-on:click="prelinkToMinex()">
        <img src="../assets/minexLogo.png" class="imgLogo" height="" alt="" />
      </div>
      <div class="profileMenu">
        <div class="profileSection">
          <img class="userIcon" src="../assets/userIcon.png" @click="toggleProfileModal" alt="" />
          <a href="javascript:void(0)" @click="toggleProfileModal"> {{ $store.state.loggedUser }} </a>
          <a href="javascript:void(0)" class="p-logout" @click="logoutUser()"> {{
            $t("header.logout") }} </a>
        </div>
        <select @change="updateLanguage()" v-model="$i18n.locale" class="select">
          <option class="localeButton" v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
            <p @click="$i18n.locale = lang"> {{ lang }} </p>
          </option>
        </select>
      </div>
    </div>
  </div>
  <!-- notification bar -->
  <transition>
    <info v-if="($store.state.msg.length > 0)" />
  </transition>
</template>

<script>
import Info from './Info.vue';
import { useCookies } from 'vue3-cookies';
import EnvProvider from 'jvjr-docker-env';

export default {
  name: 'Header',
  components: {
    Info,
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      loggedUser: '',
      langs: ["sk", "cz", "en", "de", "hu"]
    }
  },
  mounted() {
    let u = this.cookies.get('userName')
    this.loggedUser = (u != null) ? u : ''
    this.$store.state.loggedUser = (u != null) ? u : ''

    if (localStorage.getItem("lang")) {
      this.$i18n.locale = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", this.$i18n.locale);
    }

  },
  methods: {
    handleFocusOut() {
      this.classList.add('open');
    },
    logoutUser() {
      console.log('Logging out user');
      this.axios.get(EnvProvider.value('API_AUTH') + this.$root.API_LOGOUT, {withCredentials: true}
      ).then((result) => {
        console.log(result.data);
        console.log('Response data: ' + result.data);
        if (result.status == 200) {
          this.cookies.remove('userName', null, EnvProvider.value('HOST_DOMAIN'));
          this.cookies.remove('csrfToken');
          this.$store.commit('setDocuments', null);
          this.$store.commit('setLoggedUser', '');
          this.$store.commit('pushMsg', { type: 'ok', text: this.$t("StoreCommits.sucessfullLogout") });
          this.$router.go(0);
        } else {
          this.$store.commit('pushMsg', { type: 'error', text: this.$t("StoreCommits.processingFailed") })
        }
      }).catch((e) => {
        console.log(e);
        // setResponse("This is an error from catch()");
      });
      console.log('After log out')

    },
    prelinkToMinex() {
      window.location.href = EnvProvider.value('HOME_URL')
    },
    updateLanguage() {
      localStorage.setItem("lang", this.$i18n.locale);
    },

  },
};
</script>

<style scoped lang="scss" src="@/assets/css/Header.scss" />
