<template>
  <div class="flexContainer">
    <!-- File Upload Section -->
    <FileUpload v-if="!filesReady" @filesSelected="handleFilesSelected" />

    <!-- PDF Display Section -->
    <PDFComparison v-if="filesReady" @goBack="filesNotReady" :pdf1="pdf1" :pdf2="pdf2" />

  </div>
</template>


<script>
import FileUpload from "../components/FileUpload.vue";
import PDFComparison from "../components/PDFComparison.vue";

export default {
  components: {
    FileUpload,
    PDFComparison,
  },
  data() {
    return {
      pdf1: null,
      pdf2: null,
      filesReady: false, // Determines which component to display
    };
  },
  methods: {
    filesNotReady(){
      this.filesReady = false;
    },
    handleFilesSelected(pdf1, pdf2) {
      this.pdf1 = pdf1;
      this.pdf2 = pdf2;
      this.filesReady = true;
    },
  },
};

</script>

<style lang="scss" src="@/assets/css/views/HomeView.scss"></style>

