<template>
  <div class="pdfDisplaySection">
    <div class="content-container">
      <!-- Left Section with PDF columns -->
      <div class="left-section">
        <div class="left-top-bar">
          <div class="buttonSection left">
            <button class="backButton" @click="goBack()">⟵ {{ $t("compare.backBtn") }}</button>
          </div>
          <h3 class="compare-heading">{{ $t("compare.heading") }}</h3>
          <div class="right"></div>
        </div>
        <div class="zoom-controls-container">
          <!-- Zoom controls for PDF1 -->
          <div class="zoom-controls">
            <label>{{ $t("compare.zoom") }} 1:</label>
            <select id="zoomSelect1" @change="onZoomChange(1, $event)">
              <option value="25">25%</option>
              <option value="50">50%</option>
              <option value="75">75%</option>
              <option value="100" selected>100%</option>
              <option value="125">125%</option>
              <option value="150">150%</option>
              <option value="200">200%</option>
            </select>
            <button @click="zoomOut(1)">-</button>
            <button @click="zoomIn(1)">+</button>
          </div>
          <!-- Zoom controls for PDF2 -->
          <div class="zoom-controls">
            <label>{{ $t("compare.zoom") }} 2:</label>
            <select id="zoomSelect2" @change="onZoomChange(2, $event)">
              <option value="25">25%</option>
              <option value="50">50%</option>
              <option value="75">75%</option>
              <option value="100" selected>100%</option>
              <option value="125">125%</option>
              <option value="150">150%</option>
              <option value="200">200%</option>
            </select>
            <button @click="zoomOut(2)">-</button>
            <button @click="zoomIn(2)">+</button>
          </div>
        </div>
        <div class="pdf-container">
          <!-- Scrollable container for PDF1 -->
          <div class="pdf-scroll-container">
            <div class="pdf-column" id="pdfColumn1" @mousedown="startDrag($event, 1)"></div>
          </div>
          <!-- Scrollable container for PDF2 -->
          <div class="pdf-scroll-container">
            <div class="pdf-column" id="pdfColumn2" @mousedown="startDrag($event, 2)"></div>
          </div>
        </div>
      </div>

      <!-- Right Section with Sidebar -->
      <div class="right-section">
        <h3 class="compare-heading">{{ $t("compare.sidebarHeading") }}</h3>
        <div class="sidebar">
          <!-- Page Content -->
          <div v-if="currentPageContent" class="page-card">
            <h4>{{ $t("compare.sidebarPage") }} {{ currentPageContent.pageNum }}</h4>

            <div class="change-section" v-if="currentPageContent.added.length">
              <h6 class="added">{{ $t("compare.added") }}</h6>
              <div class="change-items">
                <div v-for="(item, i) in currentPageContent.added" :key="i" class="change-item">
                  <span>{{ item.word }}</span>
                </div>
              </div>
            </div>

            <div class="change-section" v-if="currentPageContent.removed.length">
              <h6 class="removed">{{ $t("compare.removed") }}</h6>
              <div class="change-items">
                <div v-for="(item, i) in currentPageContent.removed" :key="i" class="change-item">
                  <span>{{ item.word }}</span>
                </div>
              </div>
            </div>

            <div class="change-section" v-if="currentPageContent.changed.length">
              <h6 class="changed">{{ $t("compare.changed") }}</h6>
              <div class="change-items">
                <div v-for="(item, i) in currentPageContent.changed" :key="i" class="change-item">
                  <span>{{ item.word }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Pagination Controls -->
          <div class="pagination-controls">
            <button @click="previousPage" :disabled="currentPage === 1">{{ $t('compare.previous') }}</button>
            <input type="number" v-model="pageInput" @keyup.enter="goToPage" min="1" :max="totalPages" />
            <button @click="nextPage" :disabled="currentPage === totalPages">{{ $t('compare.next') }}</button>
          </div>
        </div>
      </div>

    </div>
    <div v-show="loading" class="loading">
      <p>{{ $t("compare.loadingCmp") }}</p>
    </div>
  </div>
</template>



<script>
import * as pdfjsLib from "pdfjs-dist";
import axios from 'axios';
import EnvProvider from 'jvjr-docker-env';

export default {
  emits: ["goBack"], 
  props: {
    pdf1: File,
    pdf2: File,
  },
  data() {
    return {
      loading: false,
      pdfA: null,
      pdfB: null,
      pagesDiff: [],
      currentPage: 1,
      pageInput: 1,
      sidebarContent: {},
      currentPageContent: null,  // Holds the current page's difference data
      totalPages: 0,
      pageNum: 0,
      dragging: null, // To keep track of which PDF is being dragged
      startX: 0,
      startY: 0,
      initialLeft: 0,
      initialTop: 0,
      currentZoom: { 1: 100, 2: 100 }, // Store current zoom for each PDF
      zoomLevels: [25, 50, 75, 100, 125, 150, 200] // Fixed set of zoom levels
    };
  },
  async mounted() {
    this.loading = true;
    await this.compareFiles();
    this.loading = false;
    this.storeOriginalCanvasSizes(1); // For PDF1
    this.storeOriginalCanvasSizes(2); // For PDF2
  },
  methods: {
    storeOriginalCanvasSizes(pdfNumber) {
      const pdfColumn = document.getElementById(`pdfColumn${pdfNumber}`);
      if (pdfColumn) {
        const canvases = pdfColumn.getElementsByClassName('pdf-canvas');
        for (let canvas of canvases) {
          canvas.setAttribute('data-original-width', canvas.clientWidth);
          canvas.setAttribute('data-original-height', canvas.clientHeight);
        }
      }
    },
    goBack(){
      // Clear the PDF columns
      this.clearPdfColumns();
      
      // Reset PDF data variables
      this.pdfA = null;
      this.pdfB = null;
      
      // Emit the goBack event to navigate to the previous view
      this.$emit("goBack");
    },
    clearPdfColumns() {
      const pdfColumn1 = document.getElementById('pdfColumn1');
      const pdfColumn2 = document.getElementById('pdfColumn2');

      if (pdfColumn1) {
        pdfColumn1.innerHTML = '';
      }

      if (pdfColumn2) {
        pdfColumn2.innerHTML = '';
      }
    },
    async compareFiles() {

      if(this.pdf1 == null || this.pdf2 == null){
        console.error("One or both files not selected");
        this.$store.commit('pushMsg', {type: 'error', text: this.$t("upload.notSelected")})
        return
      }

      try {
        // Code to compare files and display comparison result
        let rawData1B64 = await this.convertToBase64(this.pdf1);
        let rawData2B64 = await this.convertToBase64(this.pdf2);

        let data = { 'pdf1': rawData1B64, 'pdf2': rawData2B64 };
        const apiUrl = EnvProvider.value('API_URL') + this.$root.API_COMPARE;
        const response = await axios.post(apiUrl, data, {
        headers: { 'Content-Type': 'application/json' },
        });
        this.pdfA = response.data['pdf_A'];
        this.pdfB = response.data['pdf_B'];
        this.pagesDiff = response.data['pages_diff'];  // Store the pages_diff data
        this.pageNum = 1;  // Initialize with the first page
        await this.renderPdfFromBase64(this.pdfA, 'pdfColumn1');
        await this.renderPdfFromBase64(this.pdfB, 'pdfColumn2');
        this.updateSidebarContent();
      } catch (error) {
        console.error('Error comparing PDFs:', error);
        this.$store.commit('pushMsg', {type: 'error', text: "Unidentified error during comparison"})
      }
    },
    async convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    async renderPdfFromBase64(base64Data, columnId) {
      pdfjsLib.GlobalWorkerOptions.workerSrc = '/node_modules/pdfjs-dist/build/pdf.worker.js';

      const binaryString = atob(base64Data.split(',')[1]);
      const arrayBuffer = new ArrayBuffer(binaryString.length);
      const uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < binaryString.length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
      }

      try {
        const pdfDocument = await pdfjsLib.getDocument({ data: uint8Array }).promise;
        const totalPages = pdfDocument.numPages;

        for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
          const pdfPage = await pdfDocument.getPage(pageNum);
          
          const viewport = pdfPage.getViewport(2.0);

          const canvas = document.createElement('canvas');
          canvas.className = 'pdf-canvas';
          canvas.width = viewport.width;
          canvas.height = viewport.height;
          const ctx = canvas.getContext('2d');

          const renderTask = pdfPage.render({
            canvasContext: ctx,
            viewport: viewport,
          });
          await renderTask.promise;

          document.getElementById(columnId).appendChild(canvas);
        }
      } catch (error) {
        console.error('Error rendering PDF:', error);
      }
    },
    onZoomChange(pdfNumber, event) {
      const zoomValue = parseInt(event.target.value, 10);
      this.setZoom(pdfNumber, zoomValue);
    },
    zoomIn(pdfNumber) {
      this.adjustZoom(pdfNumber, 1); // Move to the next zoom level
    },
    zoomOut(pdfNumber) {
      this.adjustZoom(pdfNumber, -1); // Move to the previous zoom level
    },
    setZoom(pdfNumber, zoomValue) {
      const pdfColumn = document.getElementById(`pdfColumn${pdfNumber}`);
      if (pdfColumn) {
        const scale = zoomValue / 100;

        // Apply the scale transformation to the pdf-column
        pdfColumn.style.transform = `scale(${scale})`;
        pdfColumn.style.transformOrigin = 'top left';

        // Adjust the width and height of the pdf-column to accommodate zoomed content
        pdfColumn.style.width = `${100 * scale}%`;
        pdfColumn.style.height = `${100 * scale}%`;

        // Update the dropdown value
        const dropdown = document.getElementById(`zoomSelect${pdfNumber}`);
        if (dropdown) {
          dropdown.value = zoomValue;
        }
      }
    },
    adjustZoom(pdfNumber, direction) {
      const currentZoom = this.currentZoom[pdfNumber];
      const currentIndex = this.zoomLevels.indexOf(currentZoom);
      
      let newIndex = currentIndex + direction;
      if (newIndex < 0) newIndex = 0;
      if (newIndex >= this.zoomLevels.length) newIndex = this.zoomLevels.length - 1;

      const newZoom = this.zoomLevels[newIndex];
      this.currentZoom[pdfNumber] = newZoom;
      this.setZoom(pdfNumber, newZoom);
    },
    startDrag(event, pdfNumber) {
      // Prevent default behavior to avoid issues with text selection
      event.preventDefault();

      // Initialize drag start values
      this.dragging = pdfNumber;
      this.startX = event.clientX;
      this.startY = event.clientY;

      const pdfScrollContainer = document.getElementById(`pdfColumn${pdfNumber}`).parentElement;
      if (pdfScrollContainer) {
        // Store the initial scroll positions
        this.initialScrollLeft = pdfScrollContainer.scrollLeft;
        this.initialScrollTop = pdfScrollContainer.scrollTop;

        // Add mousemove and mouseup event listeners to handle dragging
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);
      }
    },
    onMouseMove(event) {
      if (this.dragging !== null) {
        const pdfScrollContainer = document.getElementById(`pdfColumn${this.dragging}`).parentElement;

        if (pdfScrollContainer) {
          // Calculate the delta of the mouse movement
          const deltaX = event.clientX - this.startX;
          const deltaY = event.clientY - this.startY;

          // Calculate new scroll positions
          let newScrollLeft = this.initialScrollLeft - deltaX;
          let newScrollTop = this.initialScrollTop - deltaY;

          // Apply boundaries to the scroll position
          newScrollLeft = Math.max(0, Math.min(newScrollLeft, pdfScrollContainer.scrollWidth - pdfScrollContainer.clientWidth));
          newScrollTop = Math.max(0, Math.min(newScrollTop, pdfScrollContainer.scrollHeight - pdfScrollContainer.clientHeight));

          // Set the new scroll position
          pdfScrollContainer.scrollLeft = newScrollLeft;
          pdfScrollContainer.scrollTop = newScrollTop;
        }
      }
    },
    onMouseUp() {
      if (this.dragging !== null) {
        // Remove event listeners when dragging stops
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);

        this.dragging = null;
      }
    },
    updateSidebarContent() {
      // Format the pagesDiff data into sidebarContent
      this.sidebarContent = this.pagesDiff.map((page, index) => ({
        pageNum: index + 1,
        added: page.added.map(item => ({ word: item.word, position: item.new[0][0] })),
        removed: page.removed.map(item => ({ word: item.word, position: item.old[0][0] })),
        changed: page.changed.map(item => ({ word: item.word, oldPosition: item.old[0][0], newPosition: item.new[0][0] })),
      }));
      this.totalPages = this.sidebarContent.length;
      this.updateCurrentPageContent();
    },
    updateCurrentPageContent() {
      this.currentPageContent = this.sidebarContent[this.currentPage - 1];
      this.pageInput = this.currentPage;
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.updateCurrentPageContent();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.updateCurrentPageContent();
      }
    },
    goToPage() {
      const page = parseInt(this.pageInput);
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.updateCurrentPageContent();
      } else {
        // Reset to valid range if the input is out of bounds
        this.pageInput = this.currentPage;
      }
    }
  },
};
</script>

<style lang="scss" src="@/assets/css/PDFComparison.scss"></style>