<template>
    <div>
      <appHeader></appHeader>
    </div>
    <div class="content">
      <router-view/>
    </div>
    <appFooter></appFooter>
</template>
<script>

import Header from "../src/components/Header.vue"
import Footer from "../src/components/Footer.vue"

export default {
  name: 'App',
  components: {
    appHeader: Header,
    appFooter: Footer,
  }
}
</script>

<style lang="scss" src="./assets/css/App.scss"/>
