import { createApp, h } from "vue";
import * as Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { dom, library } from "@fortawesome/fontawesome-svg-core";
import {
	faHome,
	faHighlighter,
	faDownload,
	faRedo,
	faFileImport,
	faPlay,
	faCaretDown
} from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";
import VueAxios from "vue-axios";
import { useCookies } from "vue3-cookies";
import EnvProvider from "jvjr-docker-env";

// localization
import { createI18n } from "vue-i18n";

library.add(
	faHome,
	faHighlighter,
	faSquare,
	faDownload,
	faRedo,
	faFileImport,
	faPlay,
	faCaretDown
);
dom.watch();
const { cookies } = useCookies();

// all localization JSONs
import messages from "./lang";

// css used for reseting
// import "reset-css";

const i18n = createI18n({
	locale: "sk",
	fallbackLocale: "en",
	messages,
	globalInjection: true,
});

// no cache in response from axios
axios.defaults.headers = {
	"Cache-Control": "no-cache",
	Pragma: "no-cache",
	Expires: "0",
};

if (EnvProvider.value("ENV") === "DEV") {
	// FOR DEVELOPMENT ONLY
	axios.defaults.withCredentials = true;
}

// Add CSRF token in header if logged in
axios.interceptors.request.use( function (config) {
  // console.log('CSRF: token in header')
  let csrf = cookies.get('csrfToken')
  let user = cookies.get('userName')
  // console.log(store.state.loggedUser)
  // console.log(EnvProvider.value('ENV'))
  if(EnvProvider.value('LOCAL') === 'true' && EnvProvider.value('ENV') === 'DEV'){
    console.log('DEV CSRF: token in header')
    config.headers['x-csrf-token'] = 'dev_bypass';
    csrf = 'dev_bypass'
  }

  if(user != ''){   
    config.headers['x-csrf-token'] = csrf;
  }

  return config;
},
error => {
  console.log(error)
  return Promise.reject(error);
}
);


const app = createApp({
	el: "#app",
	store: store,
	data() {
		return {
			importedFileName: "upload.uploadFile",
			anonymizedFields: {},
			API_LOGOUT: "/logout",
			API_COMPARE: "/compare",
			
			TOKEN_CSRF: "dev_bypass",
		};
	},
	methods: {
		clearValues() {
			this.$root.importedFileName = "upload.uploadFile";
		},
	},
	created() {
	},
	render: () => h(App),
});

app.use(router)
	.use(VueAxios, axios)
	.use(store)
	.use(i18n)
	.component("font-awesome-icon", FontAwesomeIcon)
	.mount("#app");
